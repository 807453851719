import Loader from 'components/Loader';
import SlugInput from 'components/SlugInput';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import { useFormik } from 'formik';
import { useRouter } from 'hooks/useRouter';
import { invert } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useHistory, useParams } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { LANGUAGE_ARRAY_WITH_STRING, POST_STATUS_STING } from 'utils/constants';
import * as Yup from 'yup';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import useRequest from '../../../hooks/useRequest';
import { SMKLinks } from '../../../utils/links';

const PostEdit = () => {
  const history = useHistory();
  const { id: postId } = useParams();
  const router = useRouter();
  const { query } = router;

  toast.configure();

  const [postdetails, setpostdetails] = useState();

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const onEditorStateChange = function(editorState) {
    setEditorState(editorState);
  };

  const selectStatusOptions = [];

  for (const [key, value] of Object.entries(invert(POST_STATUS_STING))) {
    selectStatusOptions?.push({
      label: key,
      value,
    });
  }

  const [getPostRequest, { loading: getPostLoading }] = useRequest({
    url: `/forum/admin/post/search`,
    method: 'POST',
    onSuccess: async (data) => {
      const postDetailsData = data?.results[0];
      setpostdetails(postDetailsData);
      const parsedContent = JSON.parse(postDetailsData?.jsonContent?.replaceAll('\\', ''));
      const contentState = convertFromRaw(parsedContent);
      setEditorState(EditorState.createWithContent(contentState));
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(err);
    },
  });

  const [updatePostRequest, { loading: updatePostLoading }] = useRequest({
    url: `/forum/admin/post/update`,
    method: 'POST',
    onSuccess: (data) => {
      const url = SMKLinks.POST_LIST;
      history.replace(url);
      toast.success(`Success! Post ( ${data?.id} ) has been updated.`);
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(err);
    },
  });

  const editInitialValues = () => {
    const statusObj = selectStatusOptions?.find((e) => e?.value == postdetails?.status);
    const languageObj = LANGUAGE_ARRAY_WITH_STRING?.find((e) => e?.value === postdetails?.language);
    const json = !isEmpty(postdetails?.jsonContent)
      ? JSON.stringify(JSON.parse(postdetails?.jsonContent), null, 2)
      : '';

    return {
      id: postdetails?.id,
      isExpertAnswer: postdetails?.isExpertAnswer,
      active: postdetails?.active,
      deleted: postdetails?.deleted,

      title: postdetails?.topic?.title,
      slug: postdetails?.topic?.slug,
      comments: postdetails?.comments,
      jsonContent: json,

      status: !isEmpty(statusObj) ? statusObj : null,
      language: !isEmpty(languageObj) ? languageObj : null,
    };
  };

  const convertJSON = (val) => {
    try {
      const parseObj = JSON.parse(val);
      return parseObj;
    } catch (err) {
      toast.error(err);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: false,
    initialValues: { ...editInitialValues() },
    onSubmit: (values) => {
      const contentState = editorState.getCurrentContent();
      const jsonContent = convertToRaw(contentState);

      const data = {
        id: postdetails?.id,
        active: values?.active,
        isExpertAnswer: values?.isExpertAnswer,
        deleted: values?.deleted,
        language: values?.language?.value,

        status: Number(values?.status?.value),

        title: values?.title,
        slug: values?.slug,
        comments: values?.comments,
        jsonContent,
      };

      updatePostRequest({
        body: {
          ...data,
        },
      });
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required('Please provide title'),
      slug: Yup.string().required('Please provide slug'),
      status: Yup.object()
        .required('Please provide for status')
        .nullable(true),
    }),
  });

  useEffect(() => {
    if (!isEmpty(postId)) {
      getPostRequest({
        body: {
          page: 1,
          size: 20,
          sortBy: 'id',
          sortOrder: 0,
          id: +postId,
          active: query?.active || false,
        },
      });
    }
  }, [postId, query?.active]);

  return (
    <div className="page-content">
      <Loader isActive={getPostLoading || updatePostLoading} />
      <Container fluid>
        <Breadcrumbs title="Location" breadcrumbItem={`${isEmpty(postId) ? 'Add' : 'Edit'}  Post`} />

        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col md={9}>
              <Card>
                <CardBody>
                  <Row className="align-items-center">
                    {postId && (
                      <Col md={4}>
                        <FormGroup className="mb-4">
                          <Label className="form-label  ">Post ID</Label>
                          <Input
                            id="id"
                            style={{ background: 'whitesmoke' }}
                            disabled
                            className="form-control"
                            {...formik.getFieldProps('id')}
                          />
                        </FormGroup>
                      </Col>
                    )}

                    <Col md={postId ? 4 : 6}>
                      <FormGroup className="mb-4">
                        <Label for="language" className="form-label  ">
                          Language
                        </Label>
                        <Select
                          id="language"
                          options={LANGUAGE_ARRAY_WITH_STRING}
                          value={formik?.values?.language}
                          onChange={(language) => formik.setFieldValue('language', language)}
                        />
                        <FormFeedback>{formik.errors.language}</FormFeedback>
                      </FormGroup>
                    </Col>

                    <Col md={postId ? 4 : 6}>
                      <FormGroup className="mb-4">
                        <Label htmlFor="status" className="form-label ">
                          Status
                        </Label>
                        <Select
                          isClearable
                          options={selectStatusOptions}
                          value={formik.values.status}
                          onChange={(status) => formik.setFieldValue('status', status)}
                        />
                        {formik.touched.status && (
                          <div className="invalid-feedback d-block">{formik.errors.status}</div>
                        )}
                      </FormGroup>
                    </Col>

                    <Col md={12}>
                      <FormGroup className="mb-4">
                        <Label for="title" className="form-label  ">
                          Topic Title
                        </Label>
                        <Input
                          id="title"
                          name="title"
                          type="text"
                          className="form-control"
                          placeholder="Title.."
                          invalid={!!(formik.touched.title && formik.errors.title)}
                          {...formik.getFieldProps('title')}
                        />
                        <FormFeedback>{formik.errors.title}</FormFeedback>
                      </FormGroup>
                    </Col>

                    <Col md={12} style={{ marginBottom: '20px' }}>
                      <SlugInput
                        formik={formik}
                        ID={postId}
                        nameValue={formik.values.name}
                        formGroupClassName={'mt-0'}
                      />
                    </Col>

                    <Col md={12}>
                      <FormGroup className="mb-4">
                        <Label for="comments" className="form-label  ">
                          Comments
                        </Label>
                        <Input
                          id="comments"
                          name="comments"
                          style={{ height: '100px' }}
                          type="textarea"
                          className="form-control"
                          placeholder="Comments.."
                          invalid={!!(formik.touched.comments && formik.errors.comments)}
                          {...formik.getFieldProps('comments')}
                        />
                        <FormFeedback>{formik.errors.comments}</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <FormGroup className="mb-4">
                        <Label for="jsonContent" className="form-label  ">
                          Content
                        </Label>
                        <Editor
                          editorState={editorState}
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          onEditorStateChange={onEditorStateChange}
                        />
                        <FormFeedback>{formik.errors.jsonContent}</FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md={3}>
              <Card>
                <CardBody>
                  <div>
                    <Button type="submit" color="primary" className="mb-3 w-100">
                      {postId ? 'Save' : 'Add'}
                    </Button>

                    <Button
                      className="w-100"
                      color="light"
                      onClick={() => {
                        history.replace(SMKLinks.POST_LIST);
                      }}>
                      Cancel
                    </Button>
                  </div>
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <Row>
                    <Col md={12}>
                      <CardTitle className="mb-3">Active </CardTitle>
                      {[true, false].map((option, i) => (
                        <div key={`active_${i}`} className="form-check mb-3 d-inline-block me-4">
                          <input
                            type="radio"
                            id={`active_${option}`}
                            name="active"
                            className="form-check-input"
                            checked={formik.values.active === option}
                            onChange={() => formik.setFieldValue('active', option)}
                          />
                          <label className="form-check-label" htmlFor={`active_${option}`}>
                            {option ? 'Yes' : 'No'}
                          </label>
                        </div>
                      ))}
                    </Col>

                    <Col md={12}>
                      <CardTitle className="mb-3">Delete </CardTitle>
                      {[true, false].map((option, i) => (
                        <div key={`deleted_${i}`} className="form-check mb-3 d-inline-block me-4">
                          <input
                            type="radio"
                            id={`deleted_${option}`}
                            name="deleted"
                            className="form-check-input"
                            checked={formik.values.deleted === option}
                            onChange={() => formik.setFieldValue('deleted', option)}
                          />
                          <label className="form-check-label" htmlFor={`deleted_${option}`}>
                            {option ? 'Yes' : 'No'}
                          </label>
                        </div>
                      ))}
                    </Col>

                    <Col md={12}>
                      <CardTitle className="mb-3">Is Expert Answer </CardTitle>
                      {[true, false].map((option, i) => (
                        <div key={`isExpertAnswer_${i}`} className="form-check mb-3 d-inline-block me-4">
                          <input
                            type="radio"
                            id={`isExpertAnswer_${option}`}
                            name="isExpertAnswer"
                            className="form-check-input"
                            checked={formik.values.isExpertAnswer === option}
                            onChange={() => formik.setFieldValue('isExpertAnswer', option)}
                          />
                          <label className="form-check-label" htmlFor={`isExpertAnswer_${option}`}>
                            {option ? 'Yes' : 'No'}
                          </label>
                        </div>
                      ))}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  );
};

export default PostEdit;
