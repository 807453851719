import SmkAsyncSelect from 'components/SmkAsyncSelect';
import useRequest from 'hooks/useRequest';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap';
import { API_BASE_URL, taxonomyType } from 'utils/constants';

const CategoriesSearch = ({ onChange, className, filters, initialValues = [] }) => {
  const [categories, setCategories] = useState(initialValues || []);

  const [taxonomyList, setTaxonomyList] = useState([]);

  useEffect(() => {
    if (!isEmpty(initialValues)) {
      setCategories(initialValues);
    }
  }, [initialValues]);

  const updateStateAndNotify = (updatedData) => {
    setCategories(updatedData);
    onChange(updatedData);
  };

  const handleRemoveItem = (id) => {
    const updatedData = categories.filter((item) => item.id !== id);
    updateStateAndNotify(updatedData);
  };

  const [gettaxonomyListRequestDefault, { loading }] = useRequest({
    url: `/common/admin/taxonomy/search`,
    method: 'POST',
    onSuccess: (data) => {
      setTaxonomyList(data?.results);
    },
  });

  useEffect(() => {
    gettaxonomyListRequestDefault({
      body: {
        page: 1,
        size: 10000,
        sortBy: 'id',
        sortOrder: 1,
        types: [taxonomyType.RECIPE_MEAL_TYPE, taxonomyType.RECIPE_CUISINE, taxonomyType.RECIPE_DIETARY_PREFERENCE],
      },
    });
  }, []);

  const recipeTypes = [
    {
      title: 'Meal Type',
      slug: taxonomyType.RECIPE_MEAL_TYPE,
    },
    {
      title: 'Cuisine',
      slug: taxonomyType.RECIPE_CUISINE,
    },
    {
      title: 'Dietary Preference',
      slug: taxonomyType.RECIPE_DIETARY_PREFERENCE,
    },
  ];

  const otherRecipeTypes = [
    {
      title: 'Food Item Type',
      slug: taxonomyType.RECIPE_FOOD_ITEM_TYPE,
    },
    {
      title: 'Main Ingredient',
      slug: taxonomyType.RECIPE_MAIN_INGREDIENT,
    },
    {
      title: 'Cooking Method',
      slug: taxonomyType.RECIPE_COOKING_METHOD,
    },
    {
      title: 'Occasion',
      slug: taxonomyType.RECIPE_OCCASION,
    },
    {
      title: 'Special Feature',
      slug: taxonomyType.RECIPE_SPECIAL_FEATURE,
    },
    {
      title: 'Other Tags',
      slug: taxonomyType.RECIPE_OTHER_TAGS,
    },
  ];

  const handleSelectChange = (res) => {
    if (!categories?.some((item) => item?.id === res?.id)) {
      const updatedData = [...categories, { name: res.name, id: res.id, slug: res?.slug, type: res?.type }];
      updateStateAndNotify(updatedData);
    } else {
      const updatedData = categories.filter((item) => item?.id !== res?.id);
      updateStateAndNotify(updatedData);
    }
  };

  return (
    <div className="mt-4">
      <Row>
        {recipeTypes?.map((type, index) => {
          return (
            <Col md={4} key={`type_${index}`}>
              <Card className="bg-light">
                <CardBody>
                  <h5 className="border bg-white p-2 mb-3 text-center w-100 rounded-2">{type?.title}</h5>
                  <ul className="ps-0">
                    {taxonomyList
                      ?.filter((e) => e?.type === type?.slug)
                      ?.map((item, index) => (
                        <li className="mb-1" style={{ listStyle: 'none' }} key={`item_${index}`}>
                          <label>
                            <input
                              type="checkbox"
                              checked={!isEmpty(categories.find((e) => e?.id === item?.id))}
                              onChange={(e) => handleSelectChange(item)}
                            />
                            <span className="ps-2">{item.name}</span>
                          </label>
                        </li>
                      ))}
                  </ul>
                </CardBody>
              </Card>
            </Col>
          );
        })}
      </Row>

      <Row>
        {otherRecipeTypes?.map((type, index) => {
          return (
            <Col md={6} key={`other_type_${index}`}>
              <Card className={className || ''}>
                <CardBody>
                  <div className="mb-3">
                    <CardTitle className="mb-3">{type?.title}</CardTitle>
                    <SmkAsyncSelect
                      acceptedKey={'name'}
                      acceptedValue={'id'}
                      placeholder="Type name.."
                      onChange={handleSelectChange}
                      isTaxonomyCategory={true}
                      fetchUrl={`${API_BASE_URL}/common/admin/taxonomy/search`}
                      filters={{
                        autoComplete: true,
                        active: true,
                        page: 1,
                        size: 500,
                        sortBy: 'id',
                        sortOrder: 0,
                        type: type?.slug,
                        ...filters,
                      }}
                      searchKeyName="name"
                    />
                  </div>

                  {!isEmpty(categories) &&
                    categories
                      ?.filter((e) => e?.type === type?.slug)
                      ?.map((item, index) => (
                        <div
                          key={item.id}
                          className="bg-white rounded-2 p-3 d-flex justify-content-between align-items-center mt-3">
                          <h6 className="mb-0">
                            {index + 1}. {item.name}
                            {!isEmpty(item.slug) && ` - ( ${item.slug} )`}
                          </h6>
                          <button
                            className="btn btn-md btn-danger"
                            type="button"
                            onClick={() => handleRemoveItem(item.id)}>
                            Remove
                          </button>
                        </div>
                      ))}
                </CardBody>
              </Card>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default CategoriesSearch;
