import { useMsal } from '@azure/msal-react';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';

// i18n
import { withTranslation } from 'react-i18next';
// Redux
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { isEmpty } from 'lodash';
import avatar2 from '../../../assets/images/users/avatar-2.jpg';
import { createStructuredSelector } from 'reselect';
import { userSelector } from 'common/globalComponents/selectors';

const stateSelector = createStructuredSelector({
  user: userSelector,
});

const HRProfileMenu = (props) => {
  const { user } = useSelector(stateSelector);

  const [menu, setMenu] = useState(false);
  const { accounts } = useMsal();

  return (
    <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="d-inline-block">
      <DropdownToggle
        className="btn header-item waves-effect d-flex align-items-center"
        id="page-header-user-dropdown"
        tag="button">
        <img className="rounded-circle header-profile-user" src={avatar2} />
        <span className="d-none d-xl-inline-block ms-2 me-1 text-start">
          {!isEmpty(accounts) ? `${accounts[0]?.name}` : user?.displayName}
          <br />
          HR
        </span>
        <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-end">
        <a href={'/logout'} className="dropdown-item">
          <i className="bx bx-power-off font-size-16 align-middle me-2 text-danger" />
          <span>{props.t('Logout')}</span>
        </a>
      </DropdownMenu>
    </Dropdown>
  );
};

HRProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
};

const mapStatetoProps = (state) => {
  // const { error, success } = state.Profile
  const { error, success } = {};
  return { error, success };
};

export default withRouter(connect(mapStatetoProps, {})(withTranslation()(HRProfileMenu)));
