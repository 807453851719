import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';

// import Charts
import { withTranslation } from 'react-i18next';
import HrWelcomeComp from 'containers/dashboard/HrWelcomeComp';
import HRStats from 'containers/dashboard/HRStats';

// i18n

const HrPage = (props) => {
  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={5}>
              <HrWelcomeComp />
            </Col>
            <Col lg={7}>
              <HRStats />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

HrPage.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(HrPage);
