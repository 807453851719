import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, useHistory } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import HrLayout from 'components/VerticalLayout/HrLayout';
import { useRouter } from 'hooks/useRouter';
import { isEmpty } from 'lodash';
import { admins_list } from 'utils/constants';
import AuthService from '../../common/auth.service';
import { isLoadingSelector, isLoggedInSelector, userSelector } from '../../common/globalComponents/selectors';
import Loader from '../../components/Loader';
import { getUser } from '../../containers/authentication/actions';
import { SMKLinks } from '../../utils/links';

const stateSelector = createStructuredSelector({
  isLoading: isLoadingSelector,
  isLoggedIn: isLoggedInSelector,
  user: userSelector,
});

const Authmiddleware = (props) => {
  const { component: Component, layout: Layout, isAuthProtected, path, ...rest } = props;
  const { isLoading, isLoggedIn, user } = useSelector(stateSelector);

  const router = useRouter();
  const dispatch = useDispatch();
  const history = useHistory();
  const [isHRRole, setIsHRRole] = useState(false);

  const hrNonAuthRoutes = ['/auth/hr'];
  const hrProtectedRoutes = ['/hr', '/job-applications/list', '/job-applications/edit/:id'];

  useEffect(() => {
    const auth = new AuthService();
    const currentAppSource = auth.getAppSourceCookie();
    const token = auth.getSessionCookie();

    if (hrNonAuthRoutes?.includes(path)) {
      if (!isEmpty(currentAppSource) && !isEmpty(token)) {
        history.replace('/hr');
        return;
      } else {
        return;
      }
    }

    if (hrProtectedRoutes.includes(path)) {
      if (!isEmpty(currentAppSource)) {
        dispatch(getUser.trigger({ currentAppSource }));
      } else {
        history.replace('/auth/hr');
        return;
      }
    }

    if (path === '/auth/login') {
      if (!isEmpty(token)) {
        const isUserHRRole = user?.roles?.find((e) => e?.name.toLowerCase() === 'hr');
        history.replace(!isEmpty(isUserHRRole) ? '/hr' : SMKLinks.DASHBOARD);
        return;
      } else {
        return;
      }
    }

    if (!isLoggedIn) {
      const token = auth.getSessionCookie();
      if (router?.pathname !== '/branding' || router?.pathname !== '/auth/microsoft') {
        if (!token) {
          history.replace(SMKLinks.LOGIN_PAGE);
        } else {
          dispatch(getUser.trigger({ currentAppSource }));
        }
      }
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (hrProtectedRoutes.includes(path) && !isEmpty(user)) {
      const isHRAdmin = admins_list.includes(user.email);
      const isUserHRRole = user?.roles?.find((e) => e?.name.toLowerCase() === 'hr');

      if (isHRAdmin && !isEmpty(isUserHRRole)) {
        setIsHRRole(true);
      } else {
        window.location.href = '/404';
      }
    }
  }, [user]);

  return (
    <Route
      {...rest}
      render={(props) => {
        return !isAuthProtected || (isLoggedIn && isAuthProtected && !isHRRole) ? (
          <Layout>
            <Loader isActive={isLoading} />
            <Component {...props} />
          </Layout>
        ) : (
          <HrLayout>
            <Loader isActive={isLoading} />
            <Component {...props} />
          </HrLayout>
        );
      }}
    />
  );
};

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
};

export default Authmiddleware;
