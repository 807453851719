import { gql } from '@apollo/client';

// Group search results
export const COMMUNITY_GROUPS_SEARCH = gql`
  query Admin_community_group_search2($searchDTO: GroupAdminSearchDTO!) {
    admin_community_group_search(searchDTO: $searchDTO) {
      success
      results {
        id
        deleted
        active
        createdById
        createdDate
        updatedDate
        approved
        approvedDate
        name
        slug
        language
        description
        imageId
        coverImageId
        isAnonymousPostAllowed
        isAnonymousCommentAllowed
        isSponsored
        sponsorId
        createdBy {
          displayName
        }
        image {
          name
          type
          original
          thumbnailSmall
          thumbnailMedium
          thumbnailLarge
          averageColor
          duration
        }
        coverImage {
          name
          type
          original
          thumbnailSmall
          thumbnailMedium
          thumbnailLarge
          averageColor
          duration
        }
        categories {
          id
          name
          slug
        }
        sponsor {
          id
          name
        }
        stats {
          # viewCount
          postCount
          commentCount
          reactionCount
        }
        members {
          id
          deleted
          active
          groupId
          role
          joiningDate
          memberId
          group {
            id
            name
            slug
          }
          member {
            displayName
            profileImage {
              name
              type
              original
              thumbnailSmall
              thumbnailMedium
              thumbnailLarge
              averageColor
              duration
            }
          }
          stats {
            # viewCount
            postCount
            commentCount
            reactionCount
          }
        }
        seo {
          title
          description
          keywords
          ogImage {
            name
            type
            original
            thumbnailSmall
            thumbnailMedium
            thumbnailLarge
            averageColor
            duration
          }
          ogImageId
        }
        translations {
          language
          id
          title
          slug
        }
      }
      pagination {
        totalCount
        currentPage
        size
      }
    }
  }
`;

export const COMMUNITY_MEMBER_SEARCH = gql`
  query community_member_search($searchDTO: GroupMemberSearchDTO!) {
    community_member_search(
        searchDTO: $searchDTO
    ) {
        success
        results {
            id
            deleted
            active
            groupId
            role
            joiningDate
            memberId
            stats {
                postCount
                commentCount
                reactionCount
                followerCount
                followingCount
                score
            }
            member {
                displayName
                designation
                bio
                profileImageId
            }
        }
        pagination {
            totalCount
            currentPage
            size
        }
    }
  }
`;

// Group get by ID
export const COMMUNITY_GROUPS_GET_BY_ID = gql`
  query Admin_community_group_getById($groupId: String!) {
    admin_community_group_getById(groupId: $groupId) {
      id
      deleted
      active
      createdById
      createdDate
      updatedDate
      approved
      approvedDate
      name
      slug
      language
      description
      imageId
      coverImageId
      isAnonymousPostAllowed
      isAnonymousCommentAllowed
      isSponsored
      sponsorId
      createdBy {
        displayName
      }
      image {
        name
        type
        original
        thumbnailSmall
        thumbnailMedium
        thumbnailLarge
        averageColor
        duration
      }
      coverImage {
        name
        type
        original
        thumbnailSmall
        thumbnailMedium
        thumbnailLarge
        averageColor
        duration
      }
      categories {
        id
        name
        slug
      }
      sponsor {
        id
        name
      }
      stats {
        # viewCount
        postCount
        commentCount
        reactionCount
      }
      members {
        id
        deleted
        active
        groupId
        role
        joiningDate
        memberId
        group {
          id
          name
          slug
        }
        member {
          displayName
          profileImage {
            name
            type
            original
            thumbnailSmall
            thumbnailMedium
            thumbnailLarge
            averageColor
            duration
          }
        }
        stats {
          # viewCount
          postCount
          commentCount
          reactionCount
        }
      }
      seo {
        title
        description
        keywords
        ogImage {
          name
          type
          original
          thumbnailSmall
          thumbnailMedium
          thumbnailLarge
          averageColor
          duration
        }
        ogImageId
      }
      translations {
        language
        id
        title
        slug
      }
    }
  }
`;

// Post List
export const COMMUNITY_POSTS_SEARCH = gql`
  query Community_post_search($searchDTO: PostSearchDTO!) {
    community_post_search(searchDTO: $searchDTO) {
      success
      results {
        id
        deleted
        active
        group {
          id
          name
        }
        title
        slug
        language
        isPinned
        isAnonymous
        stats {
          viewCount
          commentCount
          reactionCount
        }
      }
      pagination {
        totalCount
        currentPage
        size
      }
    }
  }
`;


//Report Post
export const COMMUNITY_REPORTED_POSTS_SEARCH = gql`
query Admin_community_post_report_search($searchDTO: PostReportAdminSearchDTO!) {
        admin_community_post_report_search(searchDTO: $searchDTO) {
        success
        results {
            id
            deleted
            active
            createdById
            updatedById
            createdDate
            updatedDate
            notes
            approved
            approvedDate
            approvedById
            post {
                id
                title
                active
                deleted
            }
            reason
            comments
        }
        pagination {
            totalCount
            currentPage
            size
        }
    }
  }
`;
