import { PUBLIC_URL } from 'utils/constants';

export default {
  COMMUNITY_GROUPS_LIST: `${PUBLIC_URL}/community/groups`,
  COMMUNITY_GROUPS_ADD: `${PUBLIC_URL}/community/groups/add`,
  COMMUNITY_GROUPS_EDIT: `${PUBLIC_URL}/community/groups/edit/:id`,
  COMMUNITY_GROUPS_DETAILS: `${PUBLIC_URL}/community/groups/details/:id`,
  COMMUNITY_POSTS_LIST: `${PUBLIC_URL}/community/posts`,
  COMMUNITY_POSTS_ADD: `${PUBLIC_URL}/community/posts/add`,
  COMMUNITY_POSTS_EDIT: `${PUBLIC_URL}/community/posts/edit/:id`,
  COMMUNITY_MEMBERS_EDIT: `${PUBLIC_URL}/community/members/edit/:id`,
};
