import React from 'react';
import { toast } from 'react-toastify';
import { Button, Card, CardBody } from 'reactstrap';
import CollapsibleSections from '../components/CollapsibleSections';

const StaticPageSections = ({ sections, setSections }) => {
  const defaultPayload = {
    order: '',
    title: '',
    slug: '',
    subTitle: '',
    articleIds: null,
    content: '',
    image: {},
    listItems: [],
  };

  const handleAddSection = () => {
    if (sections?.length === 0) {
      setSections([defaultPayload]);
      return;
    }

    const lastSection = sections[sections?.length - 1];

    if (!lastSection?.order || !lastSection?.title || !lastSection?.slug) {
      toast.error('Please fill required fields.');
      return;
    }

    setSections([...sections, defaultPayload]);
  };

  const handleRemoveSection = (index) => {
    const isConfirm = confirm('Are you sure you want to remove this section?');
    if (isConfirm) {
      setSections(sections?.filter((_, i) => i !== index));
    }
  };

  const handleUpdateSection = (index, field, value) => {
    setSections((prev) => prev?.map((section, i) => (i === index ? { ...section, [field]: value } : section)));
  };

  const handleAddItem = (sectionIndex) => {
    const currentSec = sections[sectionIndex];
    const lastItem = currentSec?.listItems[currentSec?.listItems?.length - 1];

    if (lastItem && (!lastItem?.title || !lastItem?.order)) {
      toast.error('Please fill required fields.');
      return;
    }

    const newItem = {
      order: '',
      title: '',
      description: '',
      image: {},
      articleIds: null,
    };
    const updatedSections = sections?.map((section, i) =>
      i === sectionIndex ? { ...section, listItems: [...section.listItems, newItem] } : section
    );
    setSections(updatedSections);
  };

  const handleRemoveItem = (sectionIndex, itemIndex) => {
    const isConfirm = confirm('Are you sure you want to remove this item?');

    if (isConfirm) {
      setSections((prevSections) =>
        prevSections.map((section, i) =>
          i === sectionIndex
            ? {
                ...section,
                listItems: (section.listItems || []).filter((_, j) => j !== itemIndex),
              }
            : section
        )
      );
    }
  };

  const handleUpdateItems = (sectionIndex, itemIndex, field, val) => {
    setSections((prevSections) => {
      return prevSections.map((section, i) => {
        if (i === sectionIndex) {
          return {
            ...section,
            listItems: (section.listItems || []).map((item, j) => (j === itemIndex ? { ...item, [field]: val } : item)),
          };
        }
        return section;
      });
    });
  };

  return (
    <div className="mb-5">
      <CollapsibleSections
        sections={sections}
        handleRemoveSection={handleRemoveSection}
        handleUpdateSection={handleUpdateSection}
        handleAddItem={handleAddItem}
        handleRemoveItem={handleRemoveItem}
        handleUpdateItems={handleUpdateItems}
      />

      <Button className="mt-3" color="success" onClick={handleAddSection}>
        <i className="bx bx-plus me-2" />
        Add Section
      </Button>
      <Card className="mt-4">
        <CardBody>
          <h6>Example of Meta Data (enter in curly bracket)</h6>
          <pre>
            {JSON.stringify(
              {
                route: 'parenting-tips',
                isBlogCategoryRoute: 'parenting-tips',
                route: 'ADVERTISE_WITH_US',
                url:
                  'https://www.hindustantimes.com/education/virtual-after-school-activities-to-keep-kids-engaged-during-covid-19/story-W32bVEg0I29mzX2ucDZ4AN.html',
              },
              null,
              2
            )}
          </pre>
        </CardBody>
      </Card>
    </div>
  );
};

export default React.memo(StaticPageSections);
