import { useLazyQuery, useMutation } from '@apollo/client';
import Loader from 'components/Loader';
import SlugInput from 'components/SlugInput';
import AssetImageUploader from 'containers/blog/BlogList/components/AssetImageUploader';
import { useFormik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { LANGUAGE_ARRAY_WITH_STRING, MODULE_TYPE } from 'utils/constants';
import { titleCaseIfExists } from 'utils/helper';
import * as Yup from 'yup';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { SMKLinks } from '../../../utils/links';
import MultipleSearch from '../components/multipleSearch';
import NutritionCard from '../components/nutritionCard';
import { ADD_FOOD_ITEMS, UPDATE_FOOD_ITEMS } from '../graphql/food_item.mutation';
import { FOOD_ITEMS_GET_BY_ID } from '../graphql/food_item.query';

const AddUpdateFoodItems = () => {
  const history = useHistory();
  const { id: foodItemID } = useParams();

  toast.configure();

  const [nutritionValues, setNutritionValues] = useState({});
  const [foodItemDetails, setFoodItemDetails] = useState();

  const [getFoofDetailsRequest, { loading: getFoofDetailsLoading }] = useLazyQuery(FOOD_ITEMS_GET_BY_ID, {
    onCompleted: (data) => {
      if (!isEmpty(data?.admin_nutrition_foodItem_getById)) {
        setFoodItemDetails(data?.admin_nutrition_foodItem_getById);
      }
    },
    onError: () => {
      toast.error('Something went wrong!');
    },
  });

  const [addFoodItemRequest, { loading: addFoodItemLoading }] = useMutation(ADD_FOOD_ITEMS, {
    onCompleted: (data) => {
      if (!isEmpty(data?.admin_nutrition_foodItem_add)) {
        toast.success(`Success! Food Item has been added.`);
        history.replace(SMKLinks.FOOD_ITEMS_LIST);
      }
    },
    onError: () => {
      toast.error('Something went wrong!');
    },
  });

  const [updateFoodItemRequest, { loading: updateFoodItemLoading }] = useMutation(UPDATE_FOOD_ITEMS, {
    onCompleted: (data) => {
      if (!isEmpty(data?.admin_nutrition_foodItem_update)) {
        toast.success(`Success! Food Item has been updated.`);
        window.location.reload();
      }
    },
    onError: () => {
      toast.error('Something went wrong!');
    },
  });

  const editInitialValues = () => {
    setNutritionValues(foodItemDetails?.nutritions);
    return {
      id: foodItemDetails?.id,
      active: foodItemDetails?.active,
      deleted: foodItemDetails?.deleted,
      isAllergen: foodItemDetails?.isAllergen,
      name: foodItemDetails?.name,
      slug: foodItemDetails?.slug,
      description: foodItemDetails?.description,
      order: foodItemDetails?.order,
      image: foodItemDetails?.image || {},
      language: foodItemDetails?.language,
      substitutes: foodItemDetails?.substitutes,
      nutritions: foodItemDetails?.nutritions,
    };
  };

  const getInitialValues = () => ({
    active: true,
    deleted: false,
    isAllergen: false,
    order: 0,
    name: '',
    slug: '',
    description: '',
    language: LANGUAGE_ARRAY_WITH_STRING[0]?.value,
    image: {},
    substitutes: [],
    nutritions: {},
  });

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: false,
    initialValues: getInitialValues(),
    onSubmit: (values) => {
      const data = {
        active: values?.active,
        deleted: values?.deleted,
        isAllergen: values?.isAllergen,
        order: values?.order,
        name: values?.name,
        slug: values?.slug,
        description: values?.description,
        language: values?.language,
        ...(!isEmpty(values?.image)
          ? {
              imageId: values?.image?.id,
            }
          : { imageId: !isEmpty(foodItemDetails) ? -1 : null }),

        image: undefined,
        substitutes: values?.substitutes,
        nutritions: {
          ...values?.nutritions,
          calories: !isEmpty(String(values?.nutritions?.calories)) ? values?.nutritions?.calories : undefined,
          glycemicIndex: !isEmpty(String(values?.nutritions?.glycemicIndex))
            ? values?.nutritions?.glycemicIndex
            : undefined,
        },
      };

      isEmpty(foodItemID)
        ? addFoodItemRequest({
            variables: {
              foodItemDTO: {
                ...data,
              },
            },
          })
        : updateFoodItemRequest({
            variables: {
              id: foodItemID,
              foodItemDTO: {
                ...data,
              },
            },
          });
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Please provide name'),
    }),
  });

  useEffect(() => {
    if (!isEmpty(foodItemDetails)) {
      formik.setValues(editInitialValues());
    }
  }, [foodItemDetails]);

  useEffect(() => {
    if (!isEmpty(foodItemID)) {
      getFoofDetailsRequest({
        variables: {
          id: foodItemID,
        },
      });
    }
  }, [foodItemID]);

  return (
    <div className="page-content">
      <Loader isActive={addFoodItemLoading || getFoofDetailsLoading || updateFoodItemLoading} />
      <Container fluid>
        <Breadcrumbs title="Food Items" breadcrumbItem={`${isEmpty(foodItemID) ? 'Add' : 'Edit'}  Food Items`} />

        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col md={9}>
              <Card>
                <CardBody>
                  <Row className="align-items-center">
                    <Col md={12}>
                      <FormGroup className="mb-4">
                        <Label for="name" className="form-label  ">
                          Name
                        </Label>
                        <Input
                          id="name"
                          name="name"
                          type="text"
                          className="form-control"
                          placeholder="Name.."
                          invalid={!!(formik.touched.name && formik.errors.name)}
                          {...formik.getFieldProps('name')}
                        />
                        <FormFeedback>{formik.errors.name}</FormFeedback>
                      </FormGroup>
                    </Col>

                    <Col md={12} className="mb-4">
                      <SlugInput
                        formik={formik}
                        ID={foodItemID}
                        nameValue={formik.values.name}
                        formGroupClassName={'mt-0'}
                        showResetRegenerate={true}
                        currentSlug={foodItemDetails?.slug}
                      />
                    </Col>

                    <Col md={12}>
                      <FormGroup className="mb-4">
                        <Label for="description" className="form-label  ">
                          Description
                        </Label>
                        <Input
                          id="description"
                          name="description"
                          style={{ height: '100px' }}
                          type="textarea"
                          className="form-control"
                          placeholder="Description.."
                          invalid={!!(formik.touched.description && formik.errors.description)}
                          {...formik.getFieldProps('description')}
                        />
                        <FormFeedback>{formik.errors.description}</FormFeedback>
                      </FormGroup>
                    </Col>

                    <Col md={12}>
                      <FormGroup className="mb-4">
                        <Label for="order" className="form-label  ">
                          Order
                        </Label>
                        <Input
                          id="order"
                          name="order"
                          type="number"
                          className="form-control"
                          placeholder="Order.."
                          invalid={!!(formik.touched.order && formik.errors.order)}
                          {...formik.getFieldProps('order')}
                        />
                        <FormFeedback>{formik.errors.order}</FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <MultipleSearch formik={formik} label={'substitutes'} />

              <NutritionCard formik={formik} formikLabel={'nutritions'} values={nutritionValues} />
            </Col>
            <Col md={3}>
              <Card>
                <CardBody>
                  <Button type="submit" color="primary" className="mb-3 w-100">
                    {foodItemID ? 'Save' : 'Add'}
                  </Button>

                  <Button
                    className="w-100"
                    color="light"
                    onClick={() => {
                      history.replace(SMKLinks.FOOD_ITEMS_LIST);
                    }}>
                    Cancel
                  </Button>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  {['active', 'deleted', 'isAllergen']?.map((elem, index) => (
                    <FormGroup className={`mb-3`} key={index}>
                      <Label className="mb-3">{elem === 'isAllergen' ? 'isAllergen' : titleCaseIfExists(elem)}</Label>
                      <div className="d-flex align-items-center">
                        {[true, false].map((option, i) => (
                          <div key={`${elem}_${i}`} className="form-check d-inline-block me-4">
                            <input
                              type="radio"
                              id={`${elem}_${option}`}
                              name={elem}
                              className="form-check-input"
                              checked={formik.values[elem] === option}
                              onChange={() => formik.setFieldValue(`${elem}`, option)}
                            />
                            <label className="form-check-label" htmlFor={`${elem}_${option}`}>
                              {option ? 'Yes' : 'No'}
                            </label>
                          </div>
                        ))}
                      </div>
                    </FormGroup>
                  ))}
                </CardBody>
              </Card>

              <Card className="border">
                <CardBody>
                  <Row>
                    <Col md={12}>
                      <CardTitle className="mb-3">Language </CardTitle>
                      {LANGUAGE_ARRAY_WITH_STRING?.map((option, i) => (
                        <div key={`language_${i}`} className="form-check mb-3 d-inline-block me-4">
                          <input
                            type="radio"
                            id={`language_${option?.value}`}
                            name="language"
                            className="form-check-input"
                            checked={formik.values.language === option?.value}
                            onChange={() => formik.setFieldValue('language', option?.value)}
                          />
                          <label className="form-check-label" htmlFor={`language_${option?.value}`}>
                            {option?.label}
                          </label>
                        </div>
                      ))}
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <AssetImageUploader
                forType={MODULE_TYPE.RECIPE}
                formik={formik}
                fieldName={'image'}
                label={'Select Image'}
              />
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  );
};

export default AddUpdateFoodItems;
