import Breadcrumbs from 'components/Common/Breadcrumb';
import TableError from 'components/TableError';
import { isEmpty } from 'lodash';
import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { Badge, Col, Container, Row, Table } from 'reactstrap';
import { printDateTimeTwoLines } from 'utils/helper';

const SiteURLList = () => {
  let pageList = [
    {
      staticPages: [
        {
          url: 'https://www.schoolmykids.com',
          urltype: 0,
        },
      ],
    },
    {
      schools: [
        {
          url: 'https://www.schoolmykids.com/schools/explore',
          urltype: 0,
        },
      ],
    },
    {
      calculators: [
        {
          url: 'https://www.schoolmykids.com/schools/explore',
          urltype: 0,
        },
      ],
    },
    {
      colleges: [
        {
          url: 'https://www.schoolmykids.com/colleges/find-colleges',
          urltype: 0,
        },
      ],
    },
    {
      worksheets: [
        {
          url: 'https://www.schoolmykids.com/worksheets',
          urltype: 0,
        },
        {
          url: 'https://www.schoolmykids.com/worksheets/printable-worksheets',
          urltype: 0,
        },
        {
          url: 'https://www.schoolmykids.com/worksheets/worksheet-generator',
          urltype: 0,
        },
        {
          url: 'https://www.schoolmykids.com/worksheets/live-worksheets',
          urltype: 0,
        },
      ],
    },
    {
      forum: [
        {
          url: 'https://www.schoolmykids.com/community-forum',
          urltype: 0,
        },
      ],
    },
    {
      blog: [
        {
          url: 'https://www.schoolmykids.com/schools/explore',
          urltype: 0,
        },
      ],
    },
    {
      ptable: [
        {
          url: 'https://www.schoolmykids.com/learn/periodic-table-of-elements',
          urltype: 0,
        },
        {
          url: 'https://www.schoolmykids.com/learn/periodic-table/property-trends',
          urltype: 0,
        },
        {
          url: 'https://www.schoolmykids.com/learn/periodic-table/element-timeline',
          urltype: 0,
        },
        {
          url: 'https://www.schoolmykids.com/learn/periodic-table/compare-elements-periodic-table',
          urltype: 0,
        },
      ],
    },
  ];
  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Pageview" breadcrumbItem="Pageview List" />

          <Row className="mb-2  align-items-center">
            <Col sm="4">
              <div className="search-box me-2 mb-2"></div>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <div className="">
                <div className="table-responsive" style={{ minHeight: 300 }}>
                  <Table className="project-list-table table-borderless">
                    <thead>
                      <tr className="table-warning">
                        <th scope="col">Module</th>
                        <th scope="col">PageType</th>
                        <th scope="col">URL</th>
                        <th scope="col">Examples</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!isEmpty(pageList) ? (
                        pageList?.map((data, index) => (
                          <tr key={index}>
                            <td>{data?.id}</td>
                            <td>{data?.source}</td>
                            <td>{data?.url}</td>
                            <td>{data?.location?.countryName + `(${data?.location?.countryCode})`}</td>
                            <td>{data?.location?.state}</td>
                            <td>{data?.location?.city}</td>
                            <td>{data?.location?.postal}</td>
                            <td>{data?.location?.ipv4}</td>
                            <td>{printDateTimeTwoLines(data?.createdDate)}</td>
                            <td>
                              <Badge className={data?.active ? 'bg-success' : 'bg-danger'}>
                                {data?.active ? 'Yes' : 'No'}
                              </Badge>
                            </td>
                            <td>
                              <Badge className={data?.deleted ? 'bg-success' : 'bg-danger'}>
                                {data?.deleted ? 'Yes' : 'No'}
                              </Badge>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <TableError />
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default SiteURLList;
