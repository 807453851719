import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import AuthService from 'common/auth.service';
import { getCurrentUserData } from 'utils/helper';
import config from '../config/config';

// Create an Apollo server
export const createApolloServer = () => {
  const dynamicLink = new ApolloLink((operation, forward) => {
    let moduleName;

    if (operation.operationName.toLowerCase()?.startsWith('admin')) {
      moduleName = operation.operationName.toLowerCase()?.split('_')[1];
    } else {
      moduleName = operation.operationName.toLowerCase()?.split('_')[0];
    }

    const httpLink = new HttpLink({
      uri: `${config.apiEndpoint}/${moduleName}/graphql`,
      credentials: 'same-origin',
    });

    const linkChain = ApolloLink.from([httpLink]);
    return linkChain.request(operation, forward);
  });

  const authLink = setContext((_, { headers }) => {
    const { apiKey } = getCurrentUserData();
    const auth = new AuthService();
    const token = auth.getSessionCookie();
    return {
      headers: {
        ...headers,
        'x-api-key': apiKey,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  return new ApolloClient({
    link: ApolloLink.from([authLink, dynamicLink]),
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
      },
      query: {
        fetchPolicy: 'no-cache',
      },
    },
  });
};
