import Breadcrumbs from 'components/Common/Breadcrumb';
import Loader from 'components/Loader';
import Paginate from 'components/Paginate';
import TableError from 'components/TableError';
import useRequest from 'hooks/useRequest';
import { useRouter } from 'hooks/useRouter';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import {
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  InputGroup,
  InputGroupText,
  Row,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import { applicationStatus, SORT_VALUES_DATE } from 'utils/constants';
import { buildURL, formatInputDate, printDateTimeTwoLines, titleCaseIfExists } from 'utils/helper';
import { SMKLinks } from 'utils/links';

const JobApplicationsListing = () => {
  const router = useRouter();
  const history = useHistory();
  toast.configure();

  const defaultFilters = {
    applicationStatus: null,
    name: '',
    email: '',
    position: '',
    city: '',
    experienceStart: '',
    experienceEnd: '',
    startDate: '',
    endDate: '',
  };

  const [applicantsList, setApplicantsList] = useState([]);
  const [applicantsPagination, setApplicantsPagination] = useState({});
  const [filters, setFilters] = useState(defaultFilters);

  const [getApplicantsRequest, { loading: getApplicantsLoading }] = useRequest({
    url: '/rha/career/admin/search',
    method: 'POST',
    onSuccess: (data) => {
      setApplicantsList(data?.results);
      setApplicantsPagination(data?.pagination);
    },
  });

  const setPageInUrl = (page) => {
    history.push({
      pathname: router.pathname,
      search: `?${new URLSearchParams({ page }).toString()}`,
    });
  };

  const handleChangePage = (page) => {
    setPageInUrl(page);

    const payload = {
      page,
      size: 20,
      ...SORT_VALUES_DATE,
      ...filters,
      applicationStatus: !isEmpty(filters?.applicationStatus) ? filters.applicationStatus.value : undefined,
      name: !isEmpty(filters?.name) ? filters.name.trim() : undefined,
      email: !isEmpty(filters?.email) ? filters.email.trim() : undefined,
      position: !isEmpty(filters?.position) ? filters.position.trim() : undefined,
      city: !isEmpty(filters?.city) ? filters.city.trim() : undefined,
      experienceStart: filters?.experienceStart ? +filters.experienceStart : undefined,
      experienceEnd: filters?.experienceEnd ? +filters.experienceEnd : undefined,
      startDate: !isEmpty(filters?.startDate) ? formatInputDate(filters.startDate) : undefined,
      endDate: !isEmpty(filters?.endDate) ? formatInputDate(filters.endDate) : undefined,
    };
    getApplicantsRequest({
      body: payload,
    });
  };

  const applyFilters = () => {
    handleChangePage(1);
  };

  const clearFilters = () => {
    setPageInUrl(1);

    setFilters({ ...defaultFilters });
    const defaultPayload = {
      page: 1,
      size: 20,
      ...SORT_VALUES_DATE,
      ...defaultFilters,
    };
    getApplicantsRequest({
      body: defaultPayload,
    });
  };

  useEffect(() => {
    handleChangePage(parseInt(router?.query?.page, 10) || 1);
  }, []);

  return (
    <div className="page-content">
      <Loader isActive={getApplicantsLoading} />
      <Container fluid>
        <Breadcrumbs title="Job Applications" breadcrumbItem="Job Applications List" />

        <Card>
          <CardBody>
            <div className="mt-4">
              <Row>
                <div className="col-lg-3 mb-4">
                  <label className="form-label">Status</label>
                  <Select
                    options={applicationStatus}
                    value={filters?.applicationStatus}
                    onChange={(elem) => setFilters({ ...filters, applicationStatus: elem })}
                  />
                </div>

                <div className="col-lg-3 mb-4">
                  <label className="form-label">Name</label>
                  <Input
                    type="text"
                    placeholder="Name..."
                    value={filters.name}
                    onChange={(e) => setFilters({ ...filters, name: e?.target?.value })}
                  />
                </div>
                <div className="col-lg-3 mb-4">
                  <label className="form-label">Email</label>
                  <Input
                    type="text"
                    placeholder="Email..."
                    value={filters.email}
                    onChange={(e) => setFilters({ ...filters, email: e?.target?.value })}
                  />
                </div>
                <div className="col-lg-3 mb-4">
                  <label className="form-label">Position</label>
                  <Input
                    type="text"
                    placeholder="Position..."
                    value={filters.position}
                    onChange={(e) => setFilters({ ...filters, position: e?.target?.value })}
                  />
                </div>
                <div className="col-lg-3 mb-4">
                  <label className="form-label">City</label>
                  <Input
                    type="text"
                    placeholder="City..."
                    value={filters.city}
                    onChange={(e) => setFilters({ ...filters, city: e?.target?.value })}
                  />
                </div>

                <div className="col-lg-3 mb-4">
                  <label className="form-label">Experience Start</label>
                  <InputGroup>
                    <Input
                      type="text"
                      placeholder="Experience Start..."
                      value={filters.experienceStart}
                      onChange={(e) => setFilters({ ...filters, experienceStart: e?.target?.value })}
                    />
                    <InputGroupText>Years</InputGroupText>
                  </InputGroup>
                </div>
                <div className="col-lg-3 mb-4">
                  <label className="form-label">Experience End</label>
                  <InputGroup>
                    <Input
                      type="text"
                      placeholder="Experience End..."
                      value={filters.experienceEnd}
                      onChange={(e) => setFilters({ ...filters, experienceEnd: e?.target?.value })}
                    />
                    <InputGroupText>Years</InputGroupText>
                  </InputGroup>
                </div>
                <div className="col-lg-3 mb-4">
                  <label htmlFor="startDate" className="form-label">
                    Created Date - From
                  </label>
                  <Input
                    type="date"
                    id="startDate"
                    placeholder="From Date"
                    value={filters.startDate}
                    onChange={(e) => setFilters({ ...filters, startDate: formatInputDate(e.target.value) })}
                  />
                </div>
                <div className="col-lg-3 mb-4">
                  <label htmlFor="endDate" className="form-label">
                    Created Date - To
                  </label>
                  <Input
                    type="date"
                    id="endDate"
                    placeholder="To Date"
                    value={filters.endDate}
                    onChange={(e) => setFilters({ ...filters, endDate: formatInputDate(e.target.value) })}
                  />
                </div>
              </Row>
            </div>
            <div className="mt-4 pt-2">
              <a className="btn btn-primary me-4" onClick={applyFilters}>
                Apply Filters
              </a>
              <a className="" onClick={clearFilters}>
                Clear
              </a>
            </div>
          </CardBody>
        </Card>

        <Row className="mb-2 align-items-center">
          <Col sm="4">
            <div className="search-box me-2 mb-2 d-inline-block">
              <div className="position-relative font-bold">{applicantsPagination?.totalCount} Applications Found</div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg="12">
            <div className="table-responsive mt-2 mb-4" style={{ minHeight: 300 }}>
              <Table className="project-list-table  table-borderless ">
                <thead>
                  <tr className="table-warning">
                    <th scope="col" style={{ width: '60px' }}>
                      S.No.
                    </th>
                    <th scope="col"></th>
                    <th scope="col">Name</th>
                    <th scope="col">Position</th>
                    <th scope="col">Email</th>
                    <th scope="col">Phone No.</th>
                    <th scope="col">Experience</th>
                    <th scope="col">Submitted Date</th>
                    <th scope="col">Source</th>
                    <th scope="col">Status</th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {!isEmpty(applicantsList) ? (
                    applicantsList?.map((data, index) => {
                      return (
                        <tr key={index}>
                          <td>{[index + 1]}</td>
                          <td>
                            {!isEmpty(data?.cvFileUrl) && (
                              <a target="_blank" href={data?.cvFileUrl} className="fs-4">
                                <i class="bx bx-download" />
                              </a>
                            )}
                          </td>
                          <td>
                            <Link
                              to={buildURL(SMKLinks.JOB_APPLICATIONS_EDIT, {
                                id: data?.id,
                              })}>
                              {data?.name}
                            </Link>
                            <br />
                            {data?.slug}
                          </td>
                          <td>{data?.position}</td>
                          <td>{data?.email}</td>
                          <td>{data?.phoneNumber}</td>
                          <td>{data?.experience} Years</td>
                          <td>{printDateTimeTwoLines(data?.createdDate)}</td>
                          <td>{data?.source}</td>
                          <td>{titleCaseIfExists(data?.applicationStatus)}</td>
                          <td>
                            <UncontrolledDropdown>
                              <DropdownToggle href="#" className="card-drop " tag="i">
                                <i role="button" className="mdi mdi-dots-vertical font-size-18 " />
                              </DropdownToggle>
                              <DropdownMenu className="dropdown-menu-end">
                                <Link
                                  to={buildURL(SMKLinks.JOB_APPLICATIONS_EDIT, {
                                    id: data?.id,
                                  })}>
                                  <DropdownItem>
                                    <i className="fas fa-pencil-alt text-success m-1 pe-3 " />
                                    Edit
                                  </DropdownItem>
                                </Link>
                                <DropdownItem
                                  onClick={() => {
                                    window.open(data?.cvFileUrl, '_blank');
                                  }}>
                                  <i className="fas fa-download text-success m-1 pe-3" />
                                  Download CV
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <TableError />
                  )}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
        {!isEmpty(applicantsList) && <Paginate pageInfo={applicantsPagination} onChangePage={handleChangePage} />}
      </Container>
    </div>
  );
};

export default JobApplicationsListing;
