import { useLazyQuery } from '@apollo/client';
import React, { useState } from 'react';
import AsyncSelect from 'react-select/async';
import { toast } from 'react-toastify';
import { COMMUNITY_GROUPS_SEARCH } from '../graphql/community.query';

function GroupAutoComplete({ selectedGroup, onSelectGroup, isNotClearable }) {
  const [inputValue, setInputValue] = useState('');

  toast.configure();

  const [getGroupsListRequest, { loading: getGroupsListLoading }] = useLazyQuery(COMMUNITY_GROUPS_SEARCH, {
    onError: () => {
      toast.error('Something went wrong!');
    },
  });

  // handle input change event
  const handleInputChange = (value) => {
    setInputValue(value);
  };

  // handle selection
  const handleChange = (value) => {
    onSelectGroup(value);
  };

  // load options using API call
  const loadOptions = (inputValue) => {
    return getGroupsListRequest({
      variables: {
        searchDTO: {
          page: 1,
          size: 50,
          active: true,
          deleted: false,
          language: 'en',
          name: inputValue,
        },
      },
    })?.then((data) => {
      return data?.data?.admin_community_group_search?.results;
    });
  };

  return (
    <AsyncSelect
      // cacheOptions
      // defaultOptions
      placeholder="Please type group name..."
      value={selectedGroup}
      getOptionLabel={(e) => `${e.name} (${e.id})`}
      getOptionValue={(e) => e.id}
      loadOptions={loadOptions}
      onInputChange={handleInputChange}
      onChange={handleChange}
      isClearable={isNotClearable ? false : true}
    />
  );
}

GroupAutoComplete.propTypes = {};

export default GroupAutoComplete;
