import Loader from 'components/Loader';
import TableError from 'components/TableError';
import useRequest from 'hooks/useRequest';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Table } from 'reactstrap';

const HRStats = () => {
  const [applicantsList, setApplicantsList] = useState([]);

  const [getApplicantsRequest, { loading: getApplicantsLoading }] = useRequest({
    url: '/rha/career/admin/stats',
    method: 'GET',
    onSuccess: (data) => {
      setApplicantsList(data);
    },
  });

  useEffect(() => {
    getApplicantsRequest();
  }, []);

  return (
    !isEmpty(applicantsList) && (
      <div>
        <Loader isActive={getApplicantsLoading} />
        <div className="table-responsive  mb-4" style={{ minHeight: 300 }}>
          <Table className="project-list-table  table-borderless ">
            <thead>
              <tr className="table-warning">
                <th scope="col">Role</th>
                <th scope="col">Status</th>
                <th scope="col">Count</th>
              </tr>
            </thead>
            <tbody>
              {applicantsList?.map((data, index) => {
                return (
                  <tr key={index}>
                    <td>{data?.role}</td>
                    <td>{data?.status}</td>
                    <td>{data?.count}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
    )
  );
};

export default HRStats;
