import { SMKLinks } from 'utils/links';
import { USER_ROLE } from '../../utils/constants';
import BabyTrackingActivityList from './analytics/BabyTrackingActivity';
import PageviewList from './analytics/Pageview';
import WorksheetDownloadList from './analytics/WorksheetDownload';
import ApiCacheList from './ApiCache';
import AppBannerListing from './appBanners';
import UpdateAppBanner from './appBanners/updateAppBanner';
import CDNCacheList from './CDNCache';
import FeatureBannerList from './FeatureBanner/FeatureBannerList';
import UpdateFeatureBanner from './FeatureBanner/UpdateFeatureBanner';
import OTHER_LINKS from './links';
import MediaList from './Media';
import AddMedia from './Media/addMedia';
import SendNotifications from './SendNotifications';
import SEOChecker from './SEOChecker';
import SubscriptionPlans from './SubscriptionPlans';
import AddUpdateSubscriptionPlan from './SubscriptionPlans/addUpdateSubscriptionPlans';
import SubscriptionOffers from './SubscriptionPlans/SubscriptionOffers';
import AddUpdateSubscriptionOffer from './SubscriptionPlans/SubscriptionOffers/addUpdateSubscriptionOffers';
import TagsList from './Tags/TagsList';
import UpdateTags from './Tags/UpdateTags';
import JobApplicationsListing from './jobApplications';
import UpdateJobApplication from './jobApplications/updateJobApplication';
import SiteURLList from './analytics/siteUrls';

export default [
  // ----------------- Tags -----------

  {
    path: OTHER_LINKS.ADD_TAGS,
    exact: true,
    component: UpdateTags,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: OTHER_LINKS.EDIT_TAGS,
    exact: true,
    component: UpdateTags,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: OTHER_LINKS.TAGS_LIST,
    exact: true,
    component: TagsList,
    role: [USER_ROLE.ADMIN],
  },

  // ----------------- Feature Banner -----------

  {
    path: OTHER_LINKS.ADD_FEATURE_BANNER,
    exact: true,
    component: UpdateFeatureBanner,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: OTHER_LINKS.EDIT_FEATURE_BANNER,
    exact: true,
    component: UpdateFeatureBanner,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: OTHER_LINKS.FEATURE_BANNER_LIST,
    exact: true,
    component: FeatureBannerList,
    role: [USER_ROLE.ADMIN],
  },

  // ----------------- API Cache -----------

  {
    path: OTHER_LINKS.API_CACHE_LIST,
    exact: true,
    component: ApiCacheList,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: OTHER_LINKS.CDN_CACHE_LIST,
    exact: true,
    component: CDNCacheList,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: OTHER_LINKS.SEO_CHECKER,
    exact: true,
    component: SEOChecker,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: OTHER_LINKS.SEND_NOTIFICATINS,
    exact: true,
    component: SendNotifications,
    role: [USER_ROLE.ADMIN],
  },

  // TPZ APP Banner

  {
    path: SMKLinks.APP_BANNERS_ADD,
    exact: true,
    component: UpdateAppBanner,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: OTHER_LINKS.APP_BANNERS_EDIT,
    exact: true,
    component: UpdateAppBanner,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: OTHER_LINKS.APP_BANNERS_LISTING,
    exact: true,
    component: AppBannerListing,
    role: [USER_ROLE.ADMIN],
  },

  {
    path: OTHER_LINKS.JOB_APPLICATIONS_LISTING,
    exact: true,
    component: JobApplicationsListing,
    role: [USER_ROLE.ADMIN],
  },

  {
    path: OTHER_LINKS.JOB_APPLICATIONS_EDIT,
    exact: true,
    component: UpdateJobApplication,
    role: [USER_ROLE.ADMIN],
  },

  // Subscriptions Plans
  {
    path: OTHER_LINKS.ADD_SUBSCRIPTION_PLAN,
    exact: true,
    component: AddUpdateSubscriptionPlan,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: OTHER_LINKS.EDIT_SUBSCRIPTION_PLAN,
    exact: true,
    component: AddUpdateSubscriptionPlan,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: OTHER_LINKS.SUBSCRIPTION_PLANS,
    exact: true,
    component: SubscriptionPlans,
    role: [USER_ROLE.ADMIN],
  },
  // Subscriptions Offers
  {
    path: OTHER_LINKS.ADD_SUBSCRIPTION_OFFER,
    exact: true,
    component: AddUpdateSubscriptionOffer,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: OTHER_LINKS.EDIT_SUBSCRIPTION_OFFER,
    exact: true,
    component: AddUpdateSubscriptionOffer,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: OTHER_LINKS.SUBSCRIPTION_OFFERS,
    exact: true,
    component: SubscriptionOffers,
    role: [USER_ROLE.ADMIN],
  },

  // --------- Sitemap ----------------

  // analytics
  {
    path: OTHER_LINKS.PAGEVIEW,
    exact: true,
    component: PageviewList,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: OTHER_LINKS.SITE_URLS,
    exact: true,
    component: SiteURLList,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: OTHER_LINKS.WORKSHEET_DOWNLOAD,
    exact: true,
    component: WorksheetDownloadList,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: OTHER_LINKS.BABY_TRACKING_ACTIVITY,
    exact: true,
    component: BabyTrackingActivityList,
    role: [USER_ROLE.ADMIN],
  },

  // Media
  {
    path: OTHER_LINKS.MEDIA_LISTING,
    exact: true,
    component: MediaList,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: OTHER_LINKS.ADD_MEDIA,
    exact: true,
    component: AddMedia,
    role: [USER_ROLE.ADMIN],
  },
];
