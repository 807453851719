import Breadcrumbs from 'components/Common/Breadcrumb';
import Loader from 'components/Loader';
import { useFormik } from 'formik';
import useRequest from 'hooks/useRequest';
import { useRouter } from 'hooks/useRouter';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
} from 'reactstrap';
import { applicationStatus, sourceValues } from 'utils/constants';
import { printDateTime, printDateTimeTwoLines, titleCaseIfExists } from 'utils/helper';
import * as Yup from 'yup';

const UpdateJobApplication = () => {
  const router = useRouter();
  const { query } = router;
  const { id: applicantID } = useParams();

  toast.configure();

  const [applicantDetails, setApplicantDetails] = useState(null);
  const [source, setSource] = useState(null);

  const [updateApplicantRequest, { loading: updateApplicantLoading }] = useRequest({
    url: `/rha/career/admin/${applicantID}`,
    method: 'PUT',
    onSuccess: () => {
      window.location.reload();
    },
  });

  const [updateStatusRequest, { loading: updateStatusLoading }] = useRequest({
    url: `/rha/career/admin/${applicantID}/update-status`,
    method: 'PUT',
    onSuccess: () => {
      window.location.reload();
    },
  });

  const [getApplicantsRequest, { loading: applicantLoading }] = useRequest({
    url: `/rha/career/admin/${applicantID}`,
    method: 'GET',
    onSuccess: (data) => {
      setApplicantDetails(data);
      setSource(data?.source);
    },
  });

  const defaultTime = moment()
    .set({ hour: 12, minute: 0, second: 0, millisecond: 0 })
    .format('YYYY-MM-DD HH:mm:ss');

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: false,
    initialValues: {
      date: defaultTime,
      status: null,
      comments: '',
    },
    onSubmit: async (values) => {
      if (applicantDetails?.status === values?.status) {
        toast.error('Selected status is same as previous status.');
        return;
      }

      updateStatusRequest({
        body: values,
      });
    },
    validationSchema: Yup.object({
      date: Yup.string().required('Date is required.'),
      status: Yup.string()
        .nullable()
        .required('Status is required.'),
      comments: Yup.string()
        .max(1000, 'Comments must be less than or equal to 1000 characters.')
        .required('Comment is required.'),
    }),
  });

  useEffect(() => {
    if (!isEmpty(applicantID)) {
      getApplicantsRequest();
    }
  }, [applicantID]);

  const applicantDetailsList = [
    {
      title: 'Personal Details',
      list: [
        { label: 'Full Name', value: applicantDetails?.name },
        { label: 'Email Address', value: applicantDetails?.email },
        { label: 'Position', value: applicantDetails?.position },
        { label: 'Submitted Date', value: printDateTime(applicantDetails?.createdDate) },
        { label: 'Updated Date', value: printDateTime(applicantDetails?.updatedDate) },
        { label: 'Phone Number', value: applicantDetails?.phoneNumber },
        { label: 'City', value: applicantDetails?.city },
      ],
    },
    {
      title: 'Professional Details',
      list: [
        { label: 'Total Experience', value: `${applicantDetails?.experience} Years` },
        { label: 'Current Salary (CTC)', value: `${applicantDetails?.currentCTC} INR` },
        { label: 'Notice Period (Days)', value: `${applicantDetails?.notice} Days` },
      ],
    },
  ];

  return (
    <div className="page-content">
      <Loader isActive={applicantLoading || updateStatusLoading || updateApplicantLoading} />
      <Container fluid>
        <Breadcrumbs
          title="Job Application"
          breadcrumbItem={`${isEmpty(query?.id) ? 'Add' : 'Edit'}  Job Application`}
        />
        <Row>
          <Col md={9}>
            {!isEmpty(applicantDetailsList) && (
              <Row>
                {applicantDetailsList?.map((data, index) => (
                  <Col md={6} key={index}>
                    <Card>
                      <CardBody>
                        <CardTitle className="bg-light p-3 text-center">{data?.title}</CardTitle>
                        <Table className="project-list-table">
                          <tbody>
                            {data?.list?.map((elem, index) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    <span className="fw-bold">{elem.label}:</span>
                                  </td>
                                  <td>{elem.value}</td>
                                </tr>
                              );
                            })}

                            {index === 1 && !isEmpty(applicantDetails?.cvFileUrl) && (
                              <tr>
                                <td>
                                  <span className="fw-bold">Resume:</span>
                                </td>
                                <td>
                                  <a target="_blank" href={applicantDetails?.cvFileUrl} className="fs-6">
                                    <i class="bx bx-download me-2" /> Download
                                  </a>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
            )}
            <Row>
              <Col md={12}>
                <Card>
                  <CardBody>
                    <FormGroup className="mb-4">
                      <Label for="bio" className="form-label fs-6">
                        Tell Us About Yourself
                      </Label>
                      <Input disabled={true} type="textarea" rows={5} value={applicantDetails?.bio} />
                    </FormGroup>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {!isEmpty(applicantDetails?.statusUpdates) && (
              <Row>
                <Col md={12}>
                  <Card>
                    <CardBody>
                      <CardTitle className="bg-light p-3 text-center">Applicant History</CardTitle>

                      <Table className="project-list-table">
                        <thead>
                          <tr className="table-warning">
                            <th scope="col">Date</th>
                            <th scope="col">Status</th>
                            <th scope="col">Comments</th>
                          </tr>
                        </thead>
                        <tbody>
                          {applicantDetails?.statusUpdates
                            ?.sort((a, b) => new Date(b?.date) - new Date(a?.date))
                            ?.map((data, index) => {
                              return (
                                <tr key={`statusUpdate_${index}`}>
                                  <td>{printDateTimeTwoLines(data?.date)}</td>
                                  <td>{titleCaseIfExists(data?.status)}</td>
                                  <td>{data?.comments}</td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </Table>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
          </Col>
          <Col md={3}>
            <Card>
              <CardBody>
                <Form onSubmit={formik.handleSubmit}>
                  <CardTitle className="bg-light p-3 text-center">Update Status</CardTitle>
                  <FormGroup className="mb-4 mt-4">
                    <Label for="comments" className="form-label">
                      Comment
                    </Label>
                    <Input
                      id="comments"
                      name="comments"
                      type="textarea"
                      rows={5}
                      className="form-control"
                      placeholder="Add a comment"
                      value={formik.values.comments}
                      onChange={(e) => formik.setFieldValue('comments', e.target.value)}
                    />
                    <FormFeedback className="d-block">{formik.errors.comments}</FormFeedback>
                  </FormGroup>
                  <FormGroup className="mb-4 mt-4">
                    <Label for="date" className="form-label">
                      Date
                    </Label>
                    <Input
                      type="datetime-local"
                      id="date"
                      placeholder="Date"
                      value={formik.values.date}
                      max={moment()?.format('YYYY-MM-DD')}
                      onChange={(e) => formik.setFieldValue('date', e.target.value)}
                    />
                    <FormFeedback className="d-block">{formik.errors.date}</FormFeedback>
                  </FormGroup>

                  <FormGroup className="mb-4">
                    <Label for="status" className="form-label  ">
                      Status
                    </Label>
                    <Select
                      options={applicationStatus}
                      placeholder="Status..."
                      value={applicationStatus?.find((ele) => ele?.value === formik.values.status)}
                      onChange={(field) => {
                        formik.setFieldValue('status', field?.value);
                      }}
                    />
                    <FormFeedback className="d-block">{formik.errors.status}</FormFeedback>
                  </FormGroup>

                  <Button type="submit" color="primary" className="w-100">
                    Save
                  </Button>
                </Form>
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <CardTitle className="bg-light p-3 text-center">Update Source</CardTitle>

                <FormGroup className="mb-4 mt-4">
                  <Label for="source" className="form-label">
                    Source
                  </Label>
                  <Select
                    options={sourceValues}
                    placeholder="Status..."
                    value={sourceValues?.find((ele) => ele?.value === source)}
                    onChange={(field) => {
                      setSource(field?.value);
                    }}
                  />
                </FormGroup>

                <Button
                  type="button"
                  color="success"
                  className="w-100"
                  onClick={() => {
                    updateApplicantRequest({
                      body: {
                        ...applicantDetails,
                        source,
                      },
                    });
                  }}>
                  Save
                </Button>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default UpdateJobApplication;
