import Loader from 'components/Loader';
import { useFormik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import React, { useState } from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, Col, Container, Form, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap';
import confirm from 'reactstrap-confirm';
import { API_BASE_URL, NotificationActionEnum, trigger_popup_payload_example } from 'utils/constants';
import { titleCaseIfExists } from 'utils/helper';
import * as Yup from 'yup';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import useRequest from '../../../hooks/useRequest';
import SmkAsyncSelect from 'components/SmkAsyncSelect';

const SendNotifications = () => {
  toast.configure();

  const [deviceIDsInputValue, setDeviceIDsInputValue] = useState('');
  const [sendOnlyToAdmin, setSendOnlyToAdmin] = useState(false);

  const [sendForNewVersionRequest, { loading: sendForNewVersionLoading }] = useRequest({
    method: 'POST',
    onSuccess: (data) => {
      toast.success('Success! Notification Version updatded.');
    },
    onError: (err) => {
      toast.error(err?.message);
    },
  });

  const [sendNotificationRequest, { loading: sendNotificationLoading }] = useRequest({
    url: '/notification/admin/send',
    method: 'POST',
    onSuccess: (data) => {
      toast.success('Success! Notification sended.');
    },
    onError: (err) => {
      toast.error(err?.message);
    },
  });

  const isJSON = (val) => {
    try {
      const parseObj = JSON.parse(val);
      return typeof parseObj === 'object' || typeof parseObj === 'Array' ? true : false;
    } catch (err) {
      toast.error(err);
      return false;
    }
  };

  const actionValues = Object.keys(NotificationActionEnum)?.map((name) => ({
    label: titleCaseIfExists(name),
    value: NotificationActionEnum[name],
  }));

  function isValidVersion(version) {
    const pattern = /^\d+(\.\d+){2,}$/;
    return pattern.test(version);
  }

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: false,
    initialValues: {
      isNewVersion: false,
      version: '',

      topic: '',
      deviceIds: [],
      userIds: [],
      notification_title: '',
      notification_body: '',
      action: '',
      payload: '',
    },
    onSubmit: async (values, { resetForm }) => {
      if (isJSON(values?.payload)) {
        const api_payload = {
          topic: values?.topic,
          deviceIds: values?.deviceIds?.map((e) => e?.id)?.filter((elem) => elem !== ''),
          userIds: sendOnlyToAdmin ? (!isEmpty(values?.userIds) ? values?.userIds?.map((e) => e?.id) : null) : ['1'],
          payload: {
            notification: {
              title: values?.notification_title,
              body: values?.notification_body,
            },
            data: {
              action: values?.action,
              payload: JSON.parse(values?.payload),
            },
          },
        };

        if (values?.isNewVersion) {
          if (isEmpty(values?.version)) {
            toast.error('Version is required for new version');
          } else if (!isValidVersion(values.version)) {
            toast.error('Invalid version format');
          } else {
            resetForm();
            sendForNewVersionRequest({
              url: `/notification/admin/send-for-new-version?version=${values.version}`,
              body: api_payload,
            });
          }
        } else {
          resetForm();
          sendNotificationRequest({
            body: api_payload,
          });
        }
      } else {
        toast.error('Please provide valid JSON payload');
      }
    },
    validationSchema: Yup.object().shape({
      // topic: Yup.string().required('Please provide topic'),
      // deviceIds: Yup.string().required('Please provide deviceIds'),
      // userIds: Yup.string().required('Please provide userIds'),
      // notification_body: Yup.string().required('Please provide notification body'),
      notification_title: Yup.string().required('Please provide notification title'),
      action: Yup.string().required('Please provide action'),
      payload: Yup.string().required('Please provide payload'),
    }),
  });

  const renderMultipleId = (name, formikvalue) => {
    const handleAddId = () => {
      formik.setFieldValue(formikvalue, [...formik.values[formikvalue], '']);
    };

    const handleRemoveId = (index) => {
      const array = formik.values[formikvalue].slice();
      array.splice(index, 1);
      formik.setFieldValue(formikvalue, array);
    };

    const handleUpdatelId = (index, value) => {
      const array = formik.values[formikvalue].slice();
      array[index] = { ...array[index], id: value };
      formik.setFieldValue(formikvalue, array);
    };

    return (
      <>
        <div className="d-flex align-items-center justify-content-between border-bottom pb-2  mb-4">
          <Label className="form-label mb-0">{name}</Label>
          <button
            type="button"
            className="btn btn-sm btn-outline-dark"
            onClick={() => {
              formik.setFieldValue('deviceIds', []);
              setDeviceIDsInputValue('');
            }}>
            Clear
          </button>
        </div>
        <Input
          className="mb-4"
          type="textarea"
          placeholder={`Enter Multiple ID's`}
          rows={5}
          value={deviceIDsInputValue}
          onChange={(e) => {
            const value = e.target.value;
            setDeviceIDsInputValue(value);
            const ids = value?.split('\n').filter((id) => id.trim() !== '');
            formik.setFieldValue(
              'deviceIds',
              ids?.map((id) => ({ id }))
            );
          }}
        />
        {formik.values[formikvalue]?.map((singleId, i) => (
          <Row>
            <Col md={10}>
              <FormGroup className="mb-4">
                <Label htmlFor="Device ID" className="form-label  ">
                  {name}
                </Label>
                <Input
                  id="device_id"
                  type={'text'}
                  // type={formikvalue === 'userIds' ? 'number' : 'text'}
                  className="form-control"
                  placeholder="ID..."
                  value={singleId.id}
                  onChange={(e) => handleUpdatelId(i, e.target.value)}
                />
                {!isEmpty(formik.errors[formikvalue]) && (
                  <div className="invalid-feedback d-block">{formik.errors[formikvalue][i]}</div>
                )}
              </FormGroup>
            </Col>
            <Col sm={2}>
              <div className="mt-4">
                <Button color="danger" className=" w-100" onClick={() => handleRemoveId(i)}>
                  <i className="bx bx-trash" />
                </Button>
              </div>
            </Col>
          </Row>
        ))}
        <Col md={12}>
          <Button type="button" className="w-100 mt-3" color="light" onClick={handleAddId}>
            <i className="bx bx-plus me-2" />
            Add {name}
          </Button>
        </Col>
      </>
    );
  };

  const renderDeviceIds = (name, formikvalue) => {
    const handleAddUserId = () => {
      formik.setFieldValue(formikvalue, [...formik.values[formikvalue], '']);
    };

    const handleRemoveUserId = (index) => {
      const array = formik.values[formikvalue].slice();
      array.splice(index, 1);
      formik.setFieldValue(formikvalue, array);
    };

    const handleUpdatelUserId = (index, value) => {
      const array = formik.values[formikvalue].slice();
      array[index] = { ...array[index], ...value };
      formik.setFieldValue(formikvalue, array);
    };

    return (
      <>
        <div className="d-flex align-items-center justify-content-between border-bottom pb-2  mb-4">
          <Label className="form-label mb-0">{name}</Label>
        </div>

        {!isEmpty(formik.values?.[formikvalue]) && (
          <div className="bg-light p-3 rounded-2 mb-5">
            {formik.values?.[formikvalue]?.map((e, index) => {
              return (
                !isEmpty(e) && (
                  <div className="card p-3">
                    <div className="d-flex align-items-center mb-1">
                      <span> {index + 1}. </span>
                      <span className="ms-2">
                        {e?.displayName} ( {e?.id} )
                      </span>
                    </div>
                    <p className="ms-2 mb-0">{e?.email}</p>
                  </div>
                )
              );
            })}
          </div>
        )}

        {formik.values[formikvalue]?.map((singleId, i) => {
          return (
            <Row>
              <Col md={10}>
                <FormGroup className="mb-4">
                  <Label htmlFor="Device ID" className="form-label  ">
                    User Id
                  </Label>
                  <SmkAsyncSelect
                    acceptedKey={'displayName'}
                    acceptedValue={'id'}
                    placeholder="Type user email.."
                    onChange={(res) => {
                      handleUpdatelUserId(i, {
                        id: res?.id,
                        email: res?.email,
                        displayName: res?.displayName,
                      });
                    }}
                    fetchUrl={`${API_BASE_URL}/user/admin/search`}
                    filters={{
                      autoComplete: true,
                      page: 1,
                      size: 10,
                      sortBy: 'id',
                      sortOrder: 0,
                      appSource: 'schoolmykids',
                    }}
                    searchKeyName="email"
                    value={
                      !isEmpty(singleId)
                        ? {
                            label: singleId?.displayName,
                            value: singleId?.id,
                          }
                        : null
                    }
                  />

                  {!isEmpty(formik.errors[formikvalue]) && (
                    <div className="invalid-feedback d-block">{formik.errors[formikvalue][i]}</div>
                  )}
                </FormGroup>
              </Col>
              <Col sm={2}>
                <div className="mt-4">
                  <Button color="danger" className=" w-100" onClick={() => handleRemoveUserId(i)}>
                    <i className="bx bx-trash" />
                  </Button>
                </div>
              </Col>
            </Row>
          );
        })}
        <Col md={12}>
          <Button type="button" className="w-100 mt-3" color="light" onClick={handleAddUserId}>
            <i className="bx bx-plus me-2" />
            Add {name}
          </Button>
        </Col>
      </>
    );
  };

  const handleConfirm = async () => {
    const result = await confirm({
      title: 'Send Notification',
      message: 'Are you sure, you want to send notification?',
      confirmText: 'Confirm',
      confirmColor: 'primary',
      cancelColor: 'link text-danger',
    });

    if (result) {
      formik.handleSubmit();
    } else {
      return;
    }
  };

  return (
    <div className="page-content">
      <Loader isActive={sendNotificationLoading || sendForNewVersionLoading} />

      <Container fluid>
        <Breadcrumbs title="Others" breadcrumbItem="Send Notifications" />
        <Form onSubmit={handleConfirm}>
          <Row>
            <Col md={9}>
              <Card>
                <CardBody>
                  <Row className="align-items-center">
                    <Col md={6}>
                      <FormGroup className="mb-4">
                        <Label for="topic" className="form-label  ">
                          Topic
                        </Label>
                        <Input
                          id="topic"
                          name="topic"
                          type="text"
                          className="form-control"
                          placeholder="Topic.."
                          invalid={!!(formik.touched.topic && formik.errors.topic)}
                          {...formik.getFieldProps('topic')}
                        />
                        <FormFeedback>{formik.errors.topic}</FormFeedback>
                      </FormGroup>
                    </Col>

                    <Col md={6}>
                      <FormGroup className="mb-4">
                        <Label for="action" className="form-label  ">
                          Action
                        </Label>
                        <Select
                          id="action"
                          options={actionValues}
                          value={
                            !isEmpty(formik.values.action)
                              ? actionValues?.find((e) => e?.value === formik.values.action)
                              : null
                          }
                          onChange={(obj) => {
                            formik.setFieldValue('action', obj?.value);
                            if (obj?.value === NotificationActionEnum.TRIGGER_POPUP) {
                              formik.setFieldValue('payload', JSON.stringify(trigger_popup_payload_example, null, 2));
                            }
                          }}
                        />
                        <FormFeedback className="d-block">{formik.errors.action}</FormFeedback>
                      </FormGroup>
                    </Col>

                    <Col md={12}>
                      <FormGroup className="mb-4">
                        <Label for="notification_title" className="form-label  ">
                          Title
                        </Label>
                        <Input
                          id="notifica  tion_title"
                          name="notification_title"
                          type="text"
                          className="form-control"
                          placeholder="Notification Title.."
                          invalid={!!(formik.touched.notification_title && formik.errors.notification_title)}
                          {...formik.getFieldProps('notification_title')}
                        />
                        <FormFeedback>{formik.errors.notification_title}</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <FormGroup className="mb-4">
                        <Label for="notification_body" className="form-label  ">
                          Body
                        </Label>
                        <Input
                          id="notification_body"
                          name="notification_body"
                          rows={5}
                          type="textarea"
                          className="form-control"
                          placeholder="Notification Body.."
                          invalid={!!(formik.touched.notification_body && formik.errors.notification_body)}
                          {...formik.getFieldProps('notification_body')}
                        />
                        <FormFeedback>{formik.errors.notification_body}</FormFeedback>
                      </FormGroup>
                    </Col>

                    <Col md={12}>
                      <FormGroup className="mb-4">
                        <Label for="payload" className="form-label  ">
                          Payload
                        </Label>
                        <Input
                          id="payload"
                          name="payload"
                          rows={5}
                          type="textarea"
                          className="form-control"
                          placeholder="Payload JSON.."
                          invalid={!!(formik.touched.payload && formik.errors.payload)}
                          {...formik.getFieldProps('payload')}
                        />
                        <FormFeedback>{formik.errors.payload}</FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Card>
                <CardBody>{renderMultipleId('Device Ids', 'deviceIds')}</CardBody>
              </Card>
              {sendOnlyToAdmin && (
                <Card>
                  <CardBody>{renderDeviceIds('User Ids', 'userIds')}</CardBody>
                </Card>
              )}
            </Col>
            <Col md={3}>
              <Card>
                <CardBody>
                  <Button type="button" onClick={handleConfirm} color="primary" className="w-100">
                    Send Now
                  </Button>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <Row>
                    <Col md={12}>
                      <FormGroup>
                        <Label className="mb-3">is New Version</Label>
                        <div className="d-flex align-items-center">
                          {[true, false].map((option, i) => (
                            <div className="form-check d-inline-block me-4">
                              <input
                                type="radio"
                                id={`isNewVersion`}
                                className="form-check-input"
                                checked={formik.values.isNewVersion === option}
                                onChange={() => formik.setFieldValue(`isNewVersion`, option)}
                              />
                              <label className="form-check-label" htmlFor={`isNewVersion`}>
                                {option ? 'Yes' : 'No'}
                              </label>
                            </div>
                          ))}
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <FormGroup className="mt-4">
                        <Label className="mb-3">Send Only to Admin</Label>
                        <div className="d-flex align-items-center">
                          {[true, false].map((option, i) => (
                            <div className="form-check d-inline-block me-4">
                              <input
                                type="radio"
                                id={`onlyToAdmin`}
                                className="form-check-input"
                                checked={sendOnlyToAdmin === option}
                                onChange={() => setSendOnlyToAdmin(option)}
                              />
                              <label className="form-check-label" htmlFor={`onlyToAdmin`}>
                                {option ? 'Yes' : 'No'}
                              </label>
                            </div>
                          ))}
                        </div>
                      </FormGroup>
                    </Col>

                    {formik.values.isNewVersion && (
                      <Col md={12}>
                        <FormGroup className="mt-4">
                          <Label for="version" className="form-label  ">
                            Version
                          </Label>
                          <Input
                            id="version"
                            name="version"
                            type="text"
                            className="form-control"
                            placeholder="Version"
                            invalid={!!(formik.touched.version && formik.errors.version)}
                            {...formik.getFieldProps('version')}
                          />
                          <FormFeedback>{formik.errors.version}</FormFeedback>
                          <p className="mb-0 mt-2">Example: 1.2.3</p>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  );
};

export default SendNotifications;
